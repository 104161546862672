"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toggleEditing = exports.saveToDb = exports.save = exports.resetSavingState = exports.removeChild = exports.moveChild = exports.failedSaving = exports.editNode = exports.doneSaving = exports.deleteNode = exports.createNode = exports.chooseTemplate = exports.changeNodeProps = exports.addChild = exports.TOGGLE_EDITING = exports.SAVE = exports.RESET_SAVING = exports.REMOVE_CHILD = exports.MOVE_CHILD = exports.FAILED_SAVING = exports.EDIT_NODE = exports.DONE_SAVING = exports.DELETE_NODE = exports.CREATE_NODE = exports.CHOOSE_TEMPLATE = exports.CHANGE_NODE_PROPS = exports.ADD_CHILD = void 0;
var _humanReadableIds = require("human-readable-ids");
var CHOOSE_TEMPLATE = exports.CHOOSE_TEMPLATE = 'CHOOSE_TEMPLATE';
var CREATE_NODE = exports.CREATE_NODE = 'CREATE_NODE';
var DELETE_NODE = exports.DELETE_NODE = 'DELETE_NODE';
var CHANGE_NODE_PROPS = exports.CHANGE_NODE_PROPS = 'CHANGE_NODE_PROPS';
var ADD_CHILD = exports.ADD_CHILD = 'ADD_CHILD';
var REMOVE_CHILD = exports.REMOVE_CHILD = 'REMOVE_CHILD';
var MOVE_CHILD = exports.MOVE_CHILD = 'MOVE_CHILD';
var EDIT_NODE = exports.EDIT_NODE = 'EDIT_NODE';
var TOGGLE_EDITING = exports.TOGGLE_EDITING = 'TOGGLE_EDITING';
var SAVE = exports.SAVE = 'SAVE';
var DONE_SAVING = exports.DONE_SAVING = 'DONE_SAVING';
var FAILED_SAVING = exports.FAILED_SAVING = 'FAILED_SAVING';
var RESET_SAVING = exports.RESET_SAVING = 'RESET_SAVING';
var chooseTemplate = exports.chooseTemplate = function chooseTemplate(template) {
  return {
    type: CHOOSE_TEMPLATE,
    template: template
  };
};
var createNode = exports.createNode = function createNode(component) {
  return {
    type: CREATE_NODE,
    nodeId: "".concat(component.toLowerCase(), "-").concat(_humanReadableIds.hri.random()),
    component: component
  };
};
var deleteNode = exports.deleteNode = function deleteNode(nodeId) {
  return {
    type: DELETE_NODE,
    nodeId: nodeId
  };
};
var addChild = exports.addChild = function addChild(nodeId, childId, component) {
  return {
    type: ADD_CHILD,
    nodeId: nodeId,
    childId: childId
  };
};
var removeChild = exports.removeChild = function removeChild(nodeId, childId) {
  return {
    type: REMOVE_CHILD,
    nodeId: nodeId,
    childId: childId
  };
};
var moveChild = exports.moveChild = function moveChild(nodeId, childId, direction) {
  return {
    type: MOVE_CHILD,
    nodeId: nodeId,
    childId: childId,
    direction: direction
  };
};
var changeNodeProps = exports.changeNodeProps = function changeNodeProps(nodeId, prop, value) {
  return {
    type: CHANGE_NODE_PROPS,
    nodeId: nodeId,
    prop: prop,
    value: value
  };
};
var editNode = exports.editNode = function editNode(nodeId) {
  return {
    type: EDIT_NODE,
    nodeId: nodeId
  };
};
var toggleEditing = exports.toggleEditing = function toggleEditing(nodeId) {
  return {
    type: TOGGLE_EDITING,
    nodeId: nodeId
  };
};
var save = exports.save = function save() {
  return {
    type: SAVE
  };
};
var doneSaving = exports.doneSaving = function doneSaving(toSave) {
  return {
    type: DONE_SAVING,
    savedState: JSON.stringify(toSave)
  };
};
var failedSaving = exports.failedSaving = function failedSaving(error) {
  return {
    type: FAILED_SAVING,
    error: error
  };
};
var resetSavingState = exports.resetSavingState = function resetSavingState() {
  return {
    type: RESET_SAVING
  };
};
var saveToDb = exports.saveToDb = function saveToDb(fetch, toSave) {
  return function (dispatch) {
    dispatch(save());
    return fetch(toSave).then(function (x) {
      setTimeout(function () {
        return dispatch(resetSavingState());
      }, 2000);
      dispatch(doneSaving(toSave));
    }).catch(function (x) {
      return dispatch(failedSaving(x));
    });
  };
};