import React, { Component } from "react"
import { Contents } from "@alkemy/common"

import {
  Header,
  Wrapper,
  Hero,
  HeroSlider,
  LaunchBlock,
  Scroller,
  withScrollWatcher,
  withMainMenu,
  withStoreLocator,
  Footer,
  Row,
  SalesPoints,
  Blob,
  CallMeBack,
  PageBlock,
  StoreLocator,
  withLoginModal,
  LoginModal,
  EditorialPreview,
  withTracking,
} from "@alkemy/components"

import withGlobalInitialProps from "../lib/withGlobalInitialProps"
import TimedCallMeBack from "../lib/TimedCallMeBack"

class Index extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showBtn: false,
    }
  }

  render() {
    const {
      getScrollRef,
      isLoginModalVisible,
      handleLoginModalClose,
    } = this.props

    const handleStoreLocatorVisibilityToggler = Contents.home.launchInfos[4]
      .isStoreLocatorTrigger
      ? this.props.handleStoreLocatorVisibilityToggler
      : undefined

    return (
      <React.Fragment>
        <Header
          {...this.props}
          contents={Contents}
          noLogoLink
          handleLoginModalOpen={this.props.handleLoginModalOpen}
          isHome
        />
        <Hero {...this.props}>
          <Wrapper>
            <HeroSlider
              getScrollRef={getScrollRef}
              items={Contents.home.sliderItems}
            />
          </Wrapper>
        </Hero>
        {/*
          <Wrapper>
            <Row>
              <LaunchBlock
                {...Contents.home.launchInfos[0]}
                triangleOrientation="horizontal"
                triangleType="top-right"
              />
              <LaunchBlock
                {...Contents.home.launchInfos[1]}
                triangleOrientation="horizontal"
                triangleType="top-right"
              />
            </Row>
          </Wrapper> */}
        <Wrapper>
          <Row>
            <LaunchBlock
              {...Contents.home.launchInfos[0]}
              triangleOrientation="horizontal"
              triangleType="top-right"
            />
            <LaunchBlock
              {...Contents.home.launchInfos[1]}
              triangleOrientation="horizontal"
              triangleType="top-right"
            />
          </Row>
        </Wrapper>
        <Wrapper>
          <Row>
            <LaunchBlock
              {...Contents.home.launchInfos[2]}
              full
              triangleOrientation="vertical"
              triangleType="bottom-left"
              hasMarginTop
            />
          </Row>
          <Blob
            size={{ width: 360, height: 360 }}
            padding={{ x: 60, y: 60 }}
            strength="30"
            gradient={{ c1: "#3ec88c", c2: "#0088ca", rot: 45 }}
            points={{ num: 20 }}
            mobileOff
          />
        </Wrapper>
        <Wrapper>
          <Row>
            <LaunchBlock
              {...Contents.home.launchInfos[3]}
              full
              triangleOrientation="horizontal"
              triangleType="top-left"
            />
          </Row>
          <Row salesPointHome>
            <SalesPoints
              {...Contents.home.launchInfos[4]}
              handleStoreLocatorVisibilityToggler={
                handleStoreLocatorVisibilityToggler
              }
            />
            <LaunchBlock
              {...Contents.home.launchInfos[5]}
              triangleOrientation="horizontal"
              triangleType="top-left"
            />
          </Row>

          <Row>
            <LaunchBlock
              {...Contents.home.launchInfos[6]}
              triangleOrientation="horizontal"
              triangleType="top-right"
            />
            <LaunchBlock
              {...Contents.home.launchInfos[7]}
              triangleOrientation="horizontal"
              triangleType="top-right"
            />
          </Row>

          <Row>
            <LaunchBlock
              {...Contents.home.launchInfos[10]}
              triangleOrientation="horizontal"
              triangleType="top-right"
            />
            <LaunchBlock
              {...Contents.home.launchInfos[11]}
              triangleOrientation="horizontal"
              triangleType="top-right"
            />
          </Row>
          <Row>
            {/*<LaunchBlock {...Contents.home.launchInfos[10]} triangleOrientation="horizontal" triangleType="top-right"/>*/}
            <LaunchBlock
              {...Contents.home.launchInfos[9]}
              triangleOrientation="horizontal"
              triangleType="top-right"
            />
            <LaunchBlock
              {...Contents.home.launchInfos[12]}
              triangleOrientation="horizontal"
              triangleType="top-right"
            />
          </Row>

          <EditorialPreview
            items={Contents.editorialPreview.index}
            title="NEWS ED EVENTI"
            appDomain={`${process.env.APP_DOMAIN}`}
            isNews
          />

          <Row>
            {Contents.home.blocks2.map((block, index) => (
              <PageBlock key={`Block${index}`} {...block} />
            ))}
          </Row>

          <CallMeBack api={`${process.env.LARAVEL_API}/callmeback`} />
          <Footer
            contents={Contents.footer}
            copyHtml={Contents.footer.copyHtml}
            handleLoginModalOpen={this.props.handleLoginModalOpen}
          />
        </Wrapper>
        {Contents.home.hasStoreLocator && (
          <StoreLocator
            isModal
            info={Contents.storeLocatorInfo}
            isVisible={this.props.isStoreLocatorVisible}
            handleStoreLocatorClose={this.props.handleStoreLocatorClose}
            appDomain={`${process.env.APP_DOMAIN}/punti-vendita/`}
            handleScroller={this.props.getScrollRef}
          />
        )}
        <LoginModal
          isVisible={isLoginModalVisible}
          {...Contents.login}
          handleLoginModalClose={handleLoginModalClose}
        />
      </React.Fragment>
    )
  }
}

const EnhanchedIndex = withTracking(
  withLoginModal(
    withStoreLocator(withScrollWatcher(withMainMenu(Index), Scroller))
  )
)

export default withGlobalInitialProps(EnhanchedIndex)
