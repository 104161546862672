"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.uploadSuccess = exports.uploadRequest = exports.uploadMedia = exports.uploadFailure = exports.removeMedia = exports.editMedia = exports.UPLOAD_SUCCESS = exports.UPLOAD_REQUEST = exports.UPLOAD_FAILURE = exports.REMOVE_MEDIA = exports.EDIT_MEDIA = void 0;
var _uuid = _interopRequireDefault(require("uuid"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var UPLOAD_REQUEST = exports.UPLOAD_REQUEST = 'UPLOAD_REQUEST';
var UPLOAD_SUCCESS = exports.UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';
var UPLOAD_FAILURE = exports.UPLOAD_FAILURE = 'UPLOAD_FAILURE';
var REMOVE_MEDIA = exports.REMOVE_MEDIA = 'REMOVE_MEDIA';
var EDIT_MEDIA = exports.EDIT_MEDIA = 'EDIT_MEDIA';
var uploadRequest = exports.uploadRequest = function uploadRequest() {
  return {
    type: UPLOAD_REQUEST
  };
};
var uploadSuccess = exports.uploadSuccess = function uploadSuccess(body) {
  return {
    type: UPLOAD_SUCCESS,
    url: body.data.url,
    id: (0, _uuid.default)()
  };
};
var uploadFailure = exports.uploadFailure = function uploadFailure() {
  return {
    type: UPLOAD_FAILURE
  };
};
var uploadMedia = exports.uploadMedia = function uploadMedia(uploadFunction, file) {
  return function (dispatch) {
    dispatch(uploadRequest());
    return uploadFunction(file).then(function (x) {
      return dispatch(uploadSuccess(x));
    }).catch(function (x) {
      return dispatch(uploadFailure());
    });
  };
};
var removeMedia = exports.removeMedia = function removeMedia(id) {
  return {
    type: REMOVE_MEDIA,
    id: id
  };
};
var editMedia = exports.editMedia = function editMedia(id, prop, value) {
  return {
    type: EDIT_MEDIA,
    id: id,
    prop: prop,
    value: value
  };
};