"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _actions = require("../actions");
var template = function template() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
  var action = arguments.length > 1 ? arguments[1] : undefined;
  if (action.type === _actions.CHOOSE_TEMPLATE) return action.template.template;
  return state;
};
var _default = exports.default = template;