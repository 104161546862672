import { createStore, compose, applyMiddleware } from "redux"
import thunk from "redux-thunk"

import reducer from "./reducer"

const composeEnhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose

const configStore = initialState => {
  return createStore(
    reducer,
    initialState,
    composeEnhancers(applyMiddleware(...[thunk]))
  )
}

export default configStore
