// pages/_app.js
import React from 'react'
import { Provider } from 'react-redux'
import App, { Container } from 'next/app'
import withRedux from 'next-redux-wrapper'
import Head from 'next/head'

import { ThemeProvider, createGlobalStyle } from 'styled-components'
import { style } from '@alkemy/components'
import { Contents } from '@alkemy/common'
import configStore from '../redux/configStore'
import { DataLayer } from '@alkemy/components'

const showChatClickToCallBanner = router => {
  const { route, asPath } = router

  return (
    ['/configurator'].includes(route) ||
    ['/', '/contatti', '/page-product'].includes(asPath)
  )
}

const getChatDetailsAndId = [
  (route, pathname) => {
    let chatDetails = ''
    let chatId = ''

    if (route === '/') {
      chatDetails = 'homepage'
      chatId = ''
    } else if (pathname === '/page-tab-product') {
      chatDetails = 'scheda_prodotti'
      chatId = 'widget-button'
    } else if (route === '/page-product') {
      chatDetails = 'scheda_prodotti'
      chatId = 'widget-button'
    }
    return { chatDetails, chatId }
  },
]
class MyApp extends App {
  constructor(props) {
    super(props)
    this.state = {
      DataLayerActive: '',
    }
  }

  static async getInitialProps({ Component, ctx }) {
    const pageProps = Component.getInitialProps
      ? await Component.getInitialProps(ctx)
      : {}

    return { pageProps }
  }
  componentDidMount() {
    const r = Math.random()
      .toString(36)
      .substring(7)
    const script = document.createElement('script')
    script.src = `${process.env.CONTAINER_COMMANDER_ACT}?cachebuster=${r}`
    document.head.appendChild(script)

    const isDataLayerActive = document.getElementById('dataLayer')
    if (isDataLayerActive) {
      this.setState({
        DataLayerActive: 'true',
      })
    } else {
      this.setState({
        DataLayerActive: 'false',
      })
    }
  }
  render() {
    const { Component, pageProps, store, router } = this.props

    const isAmbient = `${process.env.APP_DOMAIN}`
    const route = this.props.router.asPath
    const pathname = this.props.router.pathname
    const { chatDetails, chatId } = getChatDetailsAndId[0](route, pathname)
    const { theme, globalStyles, oldStyles } = style
    const DataLayerActive = this.state.DataLayerActive
    console.log('chatDetails', chatDetails)
    console.log('chatId', chatId)
    console.log('route', route)
    console.log('path', pathname)
    return (
      <Container>
        <Head>
          <link
            rel="apple-touch-icon-precomposed"
            sizes="57x57"
            href="/static/favicon/apple-touch-icon-57x57.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="114x114"
            href="/static/favicon/apple-touch-icon-114x114.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="72x72"
            href="/static/favicon/apple-touch-icon-72x72.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="144x144"
            href="/static/favicon/apple-touch-icon-144x144.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="120x120"
            href="/static/favicon/apple-touch-icon-120x120.png"
          />
          <link
            rel="apple-touch-icon-precomposed"
            sizes="152x152"
            href="/static/favicon/apple-touch-icon-152x152.png"
          />
          <link
            rel="icon"
            type="image/png"
            href="/static/favicon/favicon-32x32.png"
            sizes="32x32"
          />
          <link
            rel="icon"
            type="image/png"
            href="/static/favicon/favicon-16x16.png"
            sizes="16x16"
          />
          <meta name="application-name" content="Vivigas" />
          <meta name="msapplication-TileColor" content="#FFFFFF" />
          <meta
            name="msapplication-TileImage"
            content="/static/favicon/mstile-144x144.png"
          />
          <meta
            name="msvalidate.01"
            content="A6086C30AAC8B75A3ED7CF21946A9312"
          />
          <meta http-equiv="Content-Language" content="it" />
          <meta
            name="facebook-domain-verification"
            content="x5rt1uanklvy0sy9lmxux98qg6uuu6"
          />
          <meta name="referrer" content="no-referrer-when-downgrade" />
          {Contents.seo[route] !== undefined ? (
            <React.Fragment>
              <title>
                {Contents.seo[route].title
                  ? `${Contents.seo[route].title} |`
                  : ''}{' '}
                VIVI energia
              </title>
              <meta
                name="title"
                content={
                  Contents.seo[route].title ? Contents.seo[route].title : ''
                }
              />
              <meta
                name="description"
                content={
                  Contents.seo[route].description
                    ? Contents.seo[route].description
                    : ''
                }
              />
              <meta
                property="og:image"
                content={
                  Contents.seo[route].og_image
                    ? Contents.seo[route].og_image
                    : ''
                }
              />
              <meta
                property="twitter:card"
                content={
                  Contents.seo[route].og_image
                    ? Contents.seo[route].og_image
                    : ''
                }
              />
            </React.Fragment>
          ) : null}
          <link
            rel="stylesheet"
            type="text/css"
            href="/static/css/custom.css"
          />
          <style
            dangerouslySetInnerHTML={{
              __html: `@media screen and (min-width: 991px) {#iubenda-cs-banner.iubenda-cs-default .iubenda-cs-rationale {min-width:100vw!important; padding: 0 5%!important;}}
                      #iubenda-cs-banner > div > div > div > div.iubenda-banner-content.iubenda-custom-content{border: 1px solid #333; padding-bottom:5px!important;}
                      #iubenda-cs-banner > div > div > div > div.iubenda-banner-content.iubenda-custom-content > p{margin:auto;}`,
            }}
          />
        </Head>

        <Provider store={store}>
          <ThemeProvider theme={theme}>
            <Component {...pageProps} />

            <script
              dangerouslySetInnerHTML={{
                __html: `var _iub = _iub || [];
                _iub.csConfiguration = {
                  "askConsentAtCookiePolicyUpdate":true,
                  "cookiePolicyInOtherWindow":true,
                  "floatingPreferencesButtonDisplay":"anchored-center-left",
                  "perPurposeConsent":true,
                  "siteId":2092233,
                  "cookiePolicyId":36366894,
                  "lang":"it",
                  "banner":{
                    "acceptButtonCaptionColor":"#000000",
                    "acceptButtonColor":"#ffffff",
                    "acceptButtonDisplay":true,
                    "backgroundColor":"rgba(129,129,129,0.9)",
                    "closeButtonRejects":true,
                    "customizeButtonCaptionColor":"white",
                    "customizeButtonColor":"#212121",
                    "customizeButtonDisplay":true,
                    "explicitWithdrawal":true,
                    "fontSizeBody":"12px",
                    "listPurposes":true,
                    "logo":null,
                    "position":"bottom",
                    "textColor":"white",
                    "acceptButtonCaption":"Accetta"
                  }};`,
              }}
            />
            <meta name="referrer" content="always" />

            {DataLayerActive && DataLayerActive != 'true' && <DataLayer />}

            <script
              type="text/javascript"
              src="//cdn.iubenda.com/cs/tcf/stub-v2.js"
            ></script>
            <script
              type="text/javascript"
              src="//cdn.iubenda.com/cs/iubenda_cs.js"
              charSet="UTF-8"
              async
            ></script>

            <script
              src="https://code.jquery.com/ui/1.11.4/jquery-ui.min.js"
              integrity="sha256-xNjb53/rY+WmG+4L6tTl9m6PpqknWZvRt0rO1SRnJzw="
              crossorigin="anonymous"
            ></script>
            <script
              dangerouslySetInnerHTML={{
                __html: `localStorage.setItem('path', '${process.env.FUNNEL_DOMAIN}');`,
              }}
            />
            <script
              dangerouslySetInnerHTML={{
                __html: `localStorage.setItem('api_domain', '${process.env.WP_API}');`,
              }}
            />
            <script src="/static/js/custom.js" />
            <DataLayer />
            <script src="/static/js/tc.js" />

            {isAmbient == 'https://www.vivienergia.it' && (
              <script src="https://track.adform.net/serving/scripts/trackpoint/" />
            )}
            {isAmbient == 'https://www.vivienergia.it' && (
              <script
                dangerouslySetInnerHTML={{
                  __html: `adf.Params.PageName = encodeURIComponent('vivigas|mastertag');
            adf.Params.Divider = encodeURIComponent('|');
            adf.track(1495908);`,
                }}
              />
            )}

            <script
              crossOrigin="anonymous"
              src="https://polyfill.io/v3/polyfill.min.js?features=es2015%2Ces2016%2Ces5%2Ces6%2Ces2017%2Ces7%2CArray.prototype.find%2CObject.getOwnPropertyDescriptor%2CObject.getOwnPropertyNames%2CArray.prototype.map%2CMap%2Cdefault"
            />
            {/*
          <script
            type="text/javascript"
            crossOrigin="anonymous"
            src="https://cstatic.weborama.fr/js/advertiserv2/adperf_conversion.js"
          ></script>
          */}
            <script
              dangerouslySetInnerHTML={{
                __html: `
               window.onload = function () {
                jQuery(".breadwrap .true").removeAttr('href');
                vivObj.modalPMI();
                var CurrentUrl = window.location.href;
                if (CurrentUrl.indexOf('/casa/vivipedia/')> -1){
                  document.getElementById("WrapperSocial").style.display = "block";
                }
                $(document).on('click',vivObj.sel.$openModal,function() {
                   jQuery(".modalocator").css({"opacity":"1","visibility":"visible", "z-index":"100000",});
                });

                $(".js-open-login-modal").click(function() {
                      jQuery(".modal-area-login").css({"opacity":"1","visibility":"visible",});
                    });
                  $(".modal-area-close").click(function() {
                      jQuery(".modal-area-login").css({"opacity":"0","visibility":"hidden",});
                    });
                 $(document).on('click',vivObj.sel.$closeStoreLocator,function() {
                   jQuery(".modalocator").css({"opacity":"0","visibility":"hidden", "z-index":"0",});
                });

                $(document).on('click',vivObj.sel.$accHeader,function() {
                    vivObj.accordion($(this));
                     $('.box-card').css('min-height', '850px');
                });
                $(document).on('click',vivObj.sel.$tabHeader,function() {
                   $('.container-tab').removeClass('parent');
                   $(this).parent().closest('.container-tab').addClass('parent');
                    vivObj.tabFascia($(this));
                });
                 $(vivObj.sel.$clickCall).click(function() {
                    vivObj.clickCall($(this));
                })
                };
           `,
              }}
            />

            {chatDetails && (
              <div>
                <script
                  defer
                  src="https://sacontactswidgetst.z6.web.core.windows.net/widget.js"
                  data-config-key={chatDetails}
                  data-button-id={chatId}
                  data-max-width="600px"
                ></script>
              </div>
            )}
          </ThemeProvider>
        </Provider>
      </Container>
    )
  }
}

export default withRedux(configStore)(MyApp)
