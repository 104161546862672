"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _actions = require("../actions");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
var meta = function meta() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
    lastSavedAt: new Date().getTime(),
    lastModifiedAt: new Date().getTime()
  };
  var action = arguments.length > 1 ? arguments[1] : undefined;
  if ([_actions.CHANGE_NODE_PROPS, _actions.MOVE_CHILD, _actions.DELETE_NODE].indexOf(action.type) > -1) {
    return _objectSpread(_objectSpread({}, state), {}, {
      lastModifiedAt: new Date().getTime(),
      saved: false
    });
  }
  switch (action.type) {
    case _actions.SAVE:
      return _objectSpread(_objectSpread({}, state), {}, {
        isSaving: true,
        savingError: false
      });
    case _actions.DONE_SAVING:
      return _objectSpread(_objectSpread({}, state), {}, {
        isSaving: false,
        saved: true,
        lastSavedAt: new Date().getTime(),
        savedNodes: action.savedState
      });
    case _actions.FAILED_SAVING:
      return _objectSpread(_objectSpread({}, state), {}, {
        isSaving: false,
        savingError: true,
        saved: false
      });
    case _actions.RESET_SAVING:
      return _objectSpread(_objectSpread({}, state), {}, {
        isSaving: false,
        savingError: false,
        saved: false
      });
    default:
      return state;
  }
};
var _default = exports.default = meta;