import React, { Component } from 'react'
import getHours from 'date-fns/get_hours'
import getDay from 'date-fns/get_day'

import {
    CallMeBack
} from '@alkemy/components'

function checkTime() {
    const date = new Date()

    const day = getDay(date)
    const hours = getHours(date)

    return hours > 9 && hours < 18 && day > 0 && day < 6
    
}

class TimedCallMeBack extends Component {
    constructor() {
        super()
        this.state = {
            showBtn: checkTime()
        }
        this.timer = null

        this.update = this.update.bind(this)
    }

    update() {
        this.timer = setTimeout(() => {
            this.setState({
                showBtn: checkTime()
            }, () => this.update())

        }, 1000 * 60)
    }
    
    componentDidMount() {
        this.update()
    }

    componentWillUnmount() {
        clearTimeout(this.timer)
    }

    render() {
        if (this.state.showBtn) return <CallMeBack {...this.props} />

        return null 
    }
}

export default TimedCallMeBack