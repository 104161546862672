"use strict";

function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.node = exports.default = void 0;
var _reduxUndo = _interopRequireWildcard(require("redux-undo"));
var _actions = require("../actions");
function _getRequireWildcardCache(e) { if ("function" != typeof WeakMap) return null; var r = new WeakMap(), t = new WeakMap(); return (_getRequireWildcardCache = function _getRequireWildcardCache(e) { return e ? t : r; })(e); }
function _interopRequireWildcard(e, r) { if (!r && e && e.__esModule) return e; if (null === e || "object" != _typeof(e) && "function" != typeof e) return { default: e }; var t = _getRequireWildcardCache(r); if (t && t.has(e)) return t.get(e); var n = { __proto__: null }, a = Object.defineProperty && Object.getOwnPropertyDescriptor; for (var u in e) if ("default" !== u && Object.prototype.hasOwnProperty.call(e, u)) { var i = a ? Object.getOwnPropertyDescriptor(e, u) : null; i && (i.get || i.set) ? Object.defineProperty(n, u, i) : n[u] = e[u]; } return n.default = e, t && t.set(e, n), n; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == _typeof(i) ? i : String(i); }
function _toPrimitive(t, r) { if ("object" != _typeof(t) || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != _typeof(i)) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function arrayMove(array, fromIndex, toIndex) {
  var arr = array.slice();
  var element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
  return arr;
}
var childIds = function childIds(state, action) {
  switch (action.type) {
    case _actions.ADD_CHILD:
      return [].concat(_toConsumableArray(state), [action.childId]);
    case _actions.REMOVE_CHILD:
      return state.filter(function (id) {
        return id !== action.childId;
      });
    case _actions.MOVE_CHILD:
      var childIndex = state.indexOf(action.childId);
      return arrayMove(state, childIndex, childIndex + action.direction);
    default:
      return state;
  }
};
var node = exports.node = function node(state, action) {
  switch (action.type) {
    case _actions.CREATE_NODE:
      return {
        id: action.nodeId,
        component: action.component,
        childIds: []
      };
    case _actions.CHANGE_NODE_PROPS:
      return _objectSpread(_objectSpread({}, state), {}, {
        props: _objectSpread(_objectSpread({}, state.props), {}, _defineProperty({}, action.prop, action.value))
      });
    case _actions.TOGGLE_EDITING:
      return _objectSpread(_objectSpread({}, state), {}, {
        uneditable: !state.uneditable
      });
    case _actions.ADD_CHILD:
    case _actions.REMOVE_CHILD:
    case _actions.MOVE_CHILD:
      return _objectSpread(_objectSpread({}, state), {}, {
        childIds: childIds(state.childIds, action)
      });
    default:
      return state;
  }
};
var getAllDescendantIds = function getAllDescendantIds(state, nodeId) {
  var childIds = state[nodeId].childIds;
  if (typeof childIds === 'undefined') return [];
  return childIds.reduce(function (acc, childId) {
    return [].concat(_toConsumableArray(acc), [childId], _toConsumableArray(getAllDescendantIds(state, childId)));
  }, []);
};
var deleteMany = function deleteMany(state, ids) {
  state = _objectSpread({}, state);
  ids.forEach(function (id) {
    return delete state[id];
  });
  return state;
};
var nodes = function nodes() {
  var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var action = arguments.length > 1 ? arguments[1] : undefined;
  if (action.type === _actions.CHOOSE_TEMPLATE) {
    return action.template.nodes;
  }
  if (action.type === 'INIT_NODES') {
    return action.nodes;
  }
  var nodeId = action.nodeId;
  if (typeof nodeId === 'undefined') {
    return state;
  }
  if (action.type === _actions.DELETE_NODE) {
    var descendantIds = getAllDescendantIds(state, nodeId);
    return deleteMany(state, [nodeId].concat(_toConsumableArray(descendantIds)));
  }
  return _objectSpread(_objectSpread({}, state), {}, _defineProperty({}, nodeId, node(state[nodeId], action)));
};
var _default = exports.default = (0, _reduxUndo.default)(nodes, {
  groupBy: function groupBy(action, currentState, previousHistory) {
    if ([_actions.DELETE_NODE, _actions.REMOVE_CHILD].indexOf(action.type) > -1) {
      return 'rimozione';
    }
  },
  filter: (0, _reduxUndo.excludeAction)(_actions.EDIT_NODE)
});