import {combineReducers} from 'redux'
import {pageBuilderReducer} from '@alkemy/pagebuilder/lib/reducers'
import loginModal from './loginModal/reducer'
import storeLocatorModal from './storeLocatorModal/reducer'
import searchModal from './searchModal/reducer'

export default combineReducers({
  pageBuilder: pageBuilderReducer,
  loginModal,
  storeLocatorModal,
  searchModal,
})
