"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.pageBuilderReducer = exports.default = void 0;
var _redux = require("redux");
var _nodes = _interopRequireDefault(require("./nodes"));
var _currentlyEditing = _interopRequireDefault(require("./currentlyEditing"));
var _template = _interopRequireDefault(require("./template"));
var _sidekick = _interopRequireDefault(require("./sidekick"));
var _meta = _interopRequireDefault(require("./meta"));
var _media = _interopRequireDefault(require("./media"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var pageBuilderReducer = exports.pageBuilderReducer = (0, _redux.combineReducers)({
  template: _template.default,
  currentlyEditing: _currentlyEditing.default,
  nodes: _nodes.default,
  sidekick: _sidekick.default,
  user: function user() {
    var state = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    return state;
  },
  meta: _meta.default,
  media: _media.default
});
var reducer = (0, _redux.combineReducers)({
  pageBuilder: pageBuilderReducer
});
var _default = exports.default = reducer;